import React from 'react';
import Andre from '../assets/images/equipe/andre.png';
import Heneida from '../assets/images/equipe/heneida.png';
import Elania from '../assets/images/equipe/elania.png';

const teamMembers = [
  {
    name: 'Maria Eneida de Sá',
    role: 'Sócia Administradora',
    image: Heneida,
    description: 'Graduada em Administração de Empresas. Diretora da assopeças - Associação dos revendedores de autopeças. Membro da rede Unir-CE',
  },
  {
    name: 'Aloísio André de Sá',
    role: 'Sócio Administrador',
    image: Andre,
    description: 'Graduado em Gestão Comercial com pós-graduação em Gestão de Vendas e Relacionamentos e Técnico em Transações Imobiliárias.',
  },
  {
    name: 'Elânia Maria de Sá',
    role: 'Sócia da empresa',
    image: Elania,
    description: 'Executiva com ampla experiência em relacionamento com clientes, focada em atendimento personalizado e na satisfação contínua dos clientes.',
  },
 
];

export default function AboutSection() {
  return (
    <section id="sobre" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Sobre Nós</h2>
        <div className="flex flex-wrap justify-center">
          {teamMembers.map((member, index) => (
            <div key={index} className="w-full md:w-1/3 p-4">
              <div className="bg-white rounded-lg border-2 border-gray-200 shadow-md p-6 text-center">
                <img
                  src={member.image || Placeholder}
                  alt={`Foto de ${member.name}`}
                  className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
                />
                <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                <h4 className="text-gray-800">{member.role}</h4>
                {member.description && <p className="text-gray-600 mt-2">{member.description}</p>}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8">
          <p className="text-lg">
            Fundada em <strong>1987</strong> a Poliservice foi pensada em atender o setor de <strong>Autopeças</strong>, tanto na linha leve quanto na pesada, com a missão de ser reconhecida pela <strong>excelência no atendimento aos clientes</strong>.
          </p>
        </div>
      </div>
    </section>
  );
}