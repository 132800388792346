import React from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa'; // Importando o ícone
import { Button } from "./ui/button";

export default function AreaSection() {
  return (
    <section id="atuacao" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Onde Atuamos</h2>
        <div className="flex justify-center mb-8">
          {/* Adicionei um ícone para tornar a seção mais atraente */}
          <FaMapMarkedAlt className="w-16 h-16" />
        </div>
        <div className="flex justify-center">
          <p className="text-center text-lg">
            Atendemos <strong>todos os Varejos e Distribuidores</strong> da região, e cobrimos <strong>todo estado do Ceará</strong>, 
            trabalhando de forma <strong>estratégica e proativa</strong> para impulsionar as vendas de nossas representadas, 
            além de garantir a <strong>satisfação dos clientes</strong> e o <strong>crescimento dos negócios</strong>.
          </p>
        </div>
        <div className="flex justify-center mt-8">
          {/* Adicionei um botão para interação */}
          <a href="https://wa.me/5585992211324?text=Olá,%20vim%20pelo%20site%20e%20gostaria%20de%20obter%20mais%20informações." target="_blank" rel="noopener noreferrer">
            <Button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full">
              Saiba Mais
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
}