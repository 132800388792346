import React from 'react';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import PartnersSection from './PartnersSection';
import AreaSection from './AreaSection';
import ContactSection from './ContactSection';
import Footer from './Footer';
import Navbar from './Navbar';

export default function LandingPage() {
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Navbar />
      <main>
        <HeroSection />
        <AboutSection />
        <PartnersSection />
        <AreaSection />
        <ContactSection />
      </main>
      <Footer />
    </div>
  );
}