import React from 'react';
import { Button } from "./ui/button";
import HeroImage from '../assets/images/hero_image.webp';


export default function HeroSection() {
  return (
    <section id="inicio" className="relative h-[70vh]"> {/* Alterado de h-screen para h-[70vh] */}
      <img
        src={HeroImage}
        alt="Hero Background"
        className="absolute inset-0 w-full h-full object-cover z-0" 
      /> {/* Alterado de h-3/4 para h-full */}
      <div className="absolute inset-0 bg-black bg-opacity-30 z-10"></div> {/* Alterado de bg-opacity-50 para bg-opacity-30 */}
      <div className="relative z-20 h-full flex flex-col justify-center items-center text-white text-center">
        <h1 className="text-4xl font-bold mb-4">Poliservice Representações</h1> {/* Alterado de text-5xl para text-4xl */}
        <p className="text-lg mb-8">Sua parceira em representações comerciais no Ceará</p> {/* Alterado de text-xl para text-lg */}
        <a href="https://wa.me/5585992211324?text=Olá,%20vim%20pelo%20site%20e%20gostaria%20de%20obter%20mais%20informações." target="_blank" rel="noopener noreferrer">
          <Button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full">
            Entre em Contato
          </Button>
        </a>
      </div>
    </section>
  );
}