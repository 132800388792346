import React from 'react';
import logo from '../assets/images/logo.png';

export default function Navbar() {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
      <nav className="flex items-center justify-between p-4 bg-[#1f2937] text-[#79e1f0] h-20 w-full"> {/* Garantindo que a navbar ocupe toda a largura da tela */}
        <div className="flex items-center justify-center w-full sm:w-auto sm:ml-8"> {/* Centralizando a logo no celular e alinhando um pouco à direita em telas maiores */}
          <a href="/" className="flex items-center">
            <img src={logo} alt="Logo" className="h-16 w-auto object-contain" /> {/* Ajustando a altura da imagem */}
          </a>
        </div>
        <div className="hidden sm:flex space-x-4 items-center mr-4 sm:mr-24"> {/* Escondendo os links em dispositivos móveis */}
          <button onClick={() => scrollToSection('inicio')} className="text-white text-base hover:text-gray-400">Início</button> {/* Alterando a cor dos links para branco e diminuindo o tamanho da fonte */}
          <button onClick={() => scrollToSection('sobre')} className="text-white text-base hover:text-gray-400">Sobre</button> {/* Alterando a cor dos links para branco e diminuindo o tamanho da fonte */}
          <button onClick={() => scrollToSection('parceiros')} className="text-white text-base hover:text-gray-400">Parceiros</button> {/* Alterando a cor dos links para branco e diminuindo o tamanho da fonte */}
          <button onClick={() => scrollToSection('atuacao')} className="text-white text-base hover:text-gray-400">Atuação</button> {/* Adicionado botão para rolar até a seção "atuacao" */}
          <button onClick={() => scrollToSection('contato')} className="text-white text-base hover:text-gray-400">Contato</button> {/* Alterando a cor dos links para branco e diminuindo o tamanho da fonte */}
        </div>
      </nav>
    );
}