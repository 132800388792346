import React from 'react';
import { FaInstagram, FaWhatsapp, FaEnvelope, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import logo from '../assets/images/logo.png';


export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img src={logo} alt="Logo" className="h-16 mr-4" />
          </div>
          <div className="flex justify-center items-center space-x-4 mb-4 md:mb-0 w-full">
            <a href="https://www.instagram.com/poliservice.rep/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
              <FaInstagram size={28} />
            </a>
            <a href="https://wa.me/5585992211324?text=Olá,%20vim%20pelo%20site%20e%20gostaria%20de%20obter%20mais%20informações." target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
              <FaWhatsapp size={28} />
            </a> {/* Atualizado */}
            <a href="mailto:polirep@veloxmail.com.br" className="text-white hover:text-gray-400">
              <FaEnvelope size={28} />
            </a>
          </div>
          <div className="text-center md:text-right">
            <p className="text-sm flex items-center justify-center md:justify-end">
              <FaEnvelope className="mr-2" /> 
              <a href="mailto:polirep@veloxmail.com.br" className="text-white hover:text-gray-400">
                polirep@veloxmail.com.br
              </a>
            </p> {/* Atualizado */}
            <p className="text-sm flex items-center justify-center md:justify-end">
              <FaWhatsapp className="mr-2" /> 
              <a href="https://wa.me/5585992211324?text=Olá,%20vim%20pelo%20site%20e%20gostaria%20de%20obter%20mais%20informações." target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
                (85) 99221-1324
              </a>
            </p> {/* Atualizado */}
          </div>
        </div>
      </div>
    </footer>
  );
}