import React from 'react';
import Slider from 'react-slick';

// Importando as imagens dos parceiros
import CobraConexoes from '../assets/images/cobra_conexoes.png';
import DitecLogo from '../assets/images/logo-ditech.png';
import FConfuorto from '../assets/images/f_confuorto.png';
import Faboff from '../assets/images/faboff.png';
import MteThompson from '../assets/images/mte_thompson.png';
import AlTruck from '../assets/images/altruck.png';
import Usilux from '../assets/images/usilux.png';

export default function PartnersSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1400,
    centerMode: true, // Ativando o modo centralizado
    centerPadding: '0px', // Removendo qualquer padding extra
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false, // Desativando o modo centralizado para telas menores
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false, // Desativando o modo centralizado para telas menores
        }
      }
    ]
  };

  const partners = [
    { id: 2, src: CobraConexoes, alt: 'Cobra Conexões' },
    { id: 3, src: DitecLogo, alt: 'Ditec' },
    { id: 4, src: FConfuorto, alt: 'F. Confuorto' },
    { id: 5, src: Faboff, alt: 'Faboff' },
    { id: 6, src: MteThompson, alt: 'MTE Thompson' },
    { id: 7, src: AlTruck, alt: 'Al Truck' },
    { id: 8, src: Usilux, alt: 'Usilux' }
  ];

  return (
    <>
      <style>
        {`
          .slick-prev:before, .slick-next:before {
            color: black !important; /* Define a cor dos ícones dos botões como preto */
          }
          .slick-slide {
            padding-right: 50px !important; /* Adiciona padding à direita de cada slide */
          }
          @media (max-width: 600px) {
            .slick-prev, .slick-next {
              display: none !important; /* Esconde os botões de navegação em telas menores */
            }
          }
        `}
      </style>
      <section id="parceiros" className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Nossos Parceiros</h2>
          <Slider {...settings}>
            {partners.map((partner) => (
              <div key={partner.id} className="flex items-center justify-center mx-2">
                <img
                  src={partner.src}
                  alt={partner.alt}
                  className="w-32 h-32 object-contain border-2 border-gray-300 rounded-full shadow-md"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
}
