import React from 'react';
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Button } from "./ui/button";
import { FaWhatsapp } from 'react-icons/fa'; // Importa o ícone do WhatsApp

export default function ContactSection() {
  return (
    <section id="contato" className="py-20 bg-gray-100"> {/* Alterado para bg-gray-100 */}
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Entre em Contato</h2>
        <div className="flex justify-center"> {/* Centraliza o formulário */}
          <div className="w-full max-w-md"> {/* Define a largura máxima do formulário */}
            <form action="https://formspree.io/f/mzzbpegd" method="POST"> {/* Adiciona o action e method para o Formspree */}
              <div className="mb-4">
                <Input type="text" name="name" placeholder="Nome" className="w-full" /> {/* Adiciona o atributo name */}
              </div>
              <div className="mb-4">
                <Input type="email" name="email" placeholder="E-mail" className="w-full" /> {/* Adiciona o atributo name */}
              </div>
              <div className="mb-4">
                <Input type="tel" name="phone" placeholder="Telefone" className="w-full" /> {/* Adiciona o atributo name */}
              </div>
              <div className="mb-4">
                <Textarea name="message" placeholder="Mensagem" className="w-full" rows={4} /> {/* Adiciona o atributo name */}
              </div>
              <div className="flex justify-center"> {/* Centraliza o botão */}
                <Button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full">
                  Enviar Mensagem
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <a
        href="https://wa.me/5585992211324?text=Olá,%20vim%20pelo%20site%20e%20gostaria%20de%20obter%20mais%20informações." /* Link do WhatsApp atualizado */
        className="fixed bottom-4 right-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full shadow-lg flex items-center justify-center"
        style={{ width: '62px', height: '62px', borderRadius: '50%' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={34} /> {/* Ícone do WhatsApp */}
      </a>
    </section>
  );
}